@import url(https://fonts.googleapis.com/css2?family=Fira+Code&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Serif+Pro);

.wg-sheet {
    height:37em;
  }

.wg-sheet-right-sidepanel {
    border: 1px solid gray;
    border-left: 1px solid transparent;
    overflow-y: auto;
    padding: 1em;
    width:30em;
    height: 100%;
    float:right;
}

.wg-sheet-left-sidepanel {
    width:calc(100% - 30em);
    height: 100%;
    outline: none;
    border: 1px solid gray;
}

.wg-filter-column {
    vertical-align:top;
    display: inline-block;
    margin-right: 1.5em;
    /*width: 25%*/
}

.wg-sheet-left-bottom{
    /* height:calc(100% - 7em); */
    height:100%;
}

.wg-sheet-word-groups {
    height:100%;
    /*height:calc(100% - 5em);*/
}

.GridScrollWrapper {
    position: relative;
    transition: height 0.3s;
    border-bottom: 1px solid #e9e9e9;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
}

.GridContainer {
    padding-left: 3px;
    position: relative;
    height: 100%;
}

.GridPlaceholder {
    text-align: center;
    height: 53px;
    background: #fff;
    padding: 15px;
}

.GridScrollDummy {
    position: relative;
    min-width: 100%;
}

.VirtualizedRows {
    width: 100%;
    /*font-family: "Roboto";*/
    font-family: "Nunito";
    /*font-family: "Roboto Slab";*/
    /*font-weight: 200;*/
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-collapse: collapse;
    position: absolute;
    top: 0;
    box-sizing: border-box;
}

.GridRow {
    padding-left: 5px;
    border-left: 7px solid transparent;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    /*border-left: 7px solid #33b5e5;*/
    /*border: 2px solid transparent;*/
}

.GridRowActive {
    border-left: 7px solid #33b5e5;
    /*border: 2px solid #33b5e5;*/
}

:root {
    --editor-main-width:50em;
    --left-sidepanel-width:8em;
    --status-bar-height:40px;
    --search-bar-height:30px;
    --alerts-height:30px;
}

html, body {
    height: 100%;
    overflow: hidden;
}

#main-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
}



.status-bar-element {
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 40px;
    display: inline-block;
}

.status-bar-element.status-bar-chapter {
    padding-right: 0;
    max-width: 39em;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.search-bar {
    font-family: "Fira Code";
    height: 30px;
    height: var(--search-bar-height);
    border: 1px solid #999999;
    border-top: none;
    width: 100%;
    vertical-align: bottom;
}

.search-bar .filters-label {
    display: inline-block;
    padding-left: 30px;
}

.search-bar input {
    border: none;
    /* border-bottom: 1px solid #999999; */
}

.editor-main {
    height: 100vh;
    width: 50em;
    width: var(--editor-main-width);
    left: 8em;
    left: var(--left-sidepanel-width);
    position: fixed;
    display: inline-block;
}

.left-sidepanel {
    top: 0;
    left: 0;
    position: fixed;
    overflow: auto;
    height:100vh;
    width:8em;
    width:var(--left-sidepanel-width);
    background-color: #f8f8f8;
    border-right: 1px solid lightgrey;
}

.right-sidepanel {
    font-family: Nunito;
    top: 0;
    right: 0;
    position: fixed;
    overflow: auto;
    height:100vh;
    width: calc(100vw - 8em - 50em);
    width: calc(100vw - var(--left-sidepanel-width) - var(--editor-main-width));
    background-color: #f8f8f8;
    border-left: 1px solid lightgrey;
}

.right-sidepanel-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    height: var(--status-bar-height);
    font-family: Nunito;
    border: 1px solid #999999;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-align: center;
}

.editor {
    overflow-y: scroll;
    padding-right: 25px;
    padding-left: 15px;
    max-height: calc(100vh - 40px - 30px - 30px);
    max-height: calc(100vh - var(--status-bar-height) - var(--alerts-height) - var(--search-bar-height));
}

.selected {
    background-color: #bae5ff;
}

.sentence {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-family: "Fira Code";
    margin-bottom: 1.2em;
    font-size: .9em;
}

.editor-line {
    padding-left: 10px;
    margin-right: 10px;
    border-left: 7px solid transparent;
    position: relative;
}

.dom-line-editor {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    outline: 0 solid transparent;
    display: inline-block;
    white-space: normal;
}

.dom-line-editor.in-edit {
    min-width: 3px;
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto;
    border-bottom: 2px solid #1a9bcb;
}

.focused-line {
    border-left: 7px solid #F7C70A;
    /*border-radius: 3px;*/
}

.marked-line {
    background-color: #1a9bcb33;
    /*border-radius: 3px;*/
}

.paragraph-break {
    color: black;
    font-family: "Fira Code";
    margin-bottom: 1em;
}

.speaker-label {
    color: #78909c;
    font-family: "Fira Code";
    font-size: .9em;
    margin-top: .25em;
}

.speaker-label .dom-line-editor {
    -webkit-text-decoration: underline #78909c;
            text-decoration: underline #78909c;
}

.passage-hint {
    /*color: #1a9bcb;*/
    font-weight: bold;
    font-family: "Fira Code";
    margin-top: 2em;
    margin-bottom: .8em;
    border-bottom: 2px solid #E6E1E8;
}

.chapter-title{
    color: black;
    font-family: "Fira Code";
    font-size: 1.5em;
    margin-top: 1em;
    margin-bottom: 1em;
}

.chapter-summary {
    font-family: "Fira Code";
    font-size: 1.1em;
    color: #777777;
    font-weight: bold;
    margin-top: 1em;
}

.cultural-note {
    font-family: "Fira Code";
    font-weight: bold;
    margin-top: .5em;
    margin-bottom: .5em;
}

.metadata-block {
    font-family: "Fira Code";
    font-size: .9em;
    margin-top: 1em;
    margin-bottom: 1em;
}

.metadata-block .dom-line-editor.in-edit {
    border-bottom: 2px solid transparent;
}

.metadata-block .dom-line-editor.not-in-edit {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.metadata-block.asset_links {
    margin-bottom: 3em;
}

.metadata-block.metadata_url::before {
    content:'/**! METADATA URL';
    display:block;
}

.metadata-block.notes::before {
    content:'/**! NOTES';
    display:block;
}

.metadata-block.metadata::before {
    content:'/**! METADATA';
    display:block;
}

.metadata-block.cast::before {
    content:'/**! CAST';
    display:block;
}

.metadata-block.asset_links::before {
    content:'/**! ASSET-LINKS';
    display:block;
}

.metadata-block::after {
    content:'*/';
    display:block;
}

.vocab {
    border-bottom: 1px solid #4ED152;
}

.tricky {
    border-bottom: 1px solid darkgray;
}

.vocab.unfilled {
    background-color: palegreen;
    border-bottom: 1px solid transparent;
}

.vocab.unfilled.selected {
    border-bottom: 2px solid forestgreen;
}

.sic {
    border-bottom: 1px solid #F5832B;
}

.comment-thread-assignee {
  margin-top: 10px;
}

.comment-thread {
    min-height: 100px;
}

.comment-box textarea {
    font-family: Nunito; /*TODO weird this is needed*/
    width: 100%;
}

.comment-thread {
    font-size: .9em;
    margin: 0px 10px 30px 10px;
}

.thread-item {
    -webkit-filter: drop-shadow(-1px -1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.15));
            filter: drop-shadow(-1px -1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.15));
    /*background-color: #f8e896;*/
    background-color: #fff5bf;
    margin: .75rem 0;
    padding: 5px;
}

.thread-item.selected {
  background-color: #ffed8f;
  /*background-color: #f8e896;*/
  /*background-color: #FFDF5E;*/
  /*border: 1px solid red;*/
}

.thread-item-attribution {
    margin-top: 4px;
    border-top: 1px solid darkgray;
    font-size: .8em;
}

.word-group-editor {
    font-size: .9em;
}

.right-sidepanel .word-group-editor {
    padding: 20px 15px 0px 15px;
}

.word-group-edit-form .md-form {
    margin: .25rem 0;
}

.word-group-edit-form .md-form label.active {
    font-size: inherit;
}

.word-group-edit-form .form-control {
    font-size: inherit;
}

.word-group-editor .btn.btn-sm {
    margin: 0;
    margin-right: .5rem;
    padding: .3rem 1rem;
}

.word-range-select {
    border-bottom: 1px solid darkgray;
    margin-bottom: .5rem;
}

.word-group-thread-container {
    /*border-top: 1px solid darkgray;*/
    border-bottom: 1px solid darkgray;
    margin: .5rem 0;
    padding: 0rem 0;
}

.word-range-select {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.word-range-selection {
    background-color: #bae5ff;
}

.status-bar {
    font-family: "Fira Code";
    height: 40px;
    height: var(--status-bar-height);
    border: 1px solid #999999;
    border-bottom: none;
    width: 100%;
    vertical-align: bottom;
}

.alerts {
    height: 30px;
    height: var(--alerts-height);
    width: 100%;
    border: 1px solid lightgrey;
    border-top: 3px solid lightgrey;
    margin-bottom: 5px;
    padding-left: 10px;
}

.alert2 {
    color:#F7C70A;
    font-weight: bold;
}

.alert3 {
    color: red
}

.search-match {
    background-color: #ff943d28;
}

.has-discussion::after {
    content:"";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='rgb(248,232,150)' d='M 21.9891,3.99805C 21.9891,2.89404 21.1031,1.99805 19.9991,1.99805L 3.99913,1.99805C 2.89512,1.99805 1.99913,2.89404 1.99913,3.99805L 1.99913,15.998C 1.99913,17.1021 2.89512,17.998 3.99913,17.998L 17.9991,17.998L 21.9991,21.998L 21.9891,3.99805 Z '%3E%3C/path%3E%3C/svg%3E");
    display:inline;
    width:30px;
    height:20px;
    position: absolute;
    right: -35px;
    top: 0;
    /*margin:10px 5px 0 10px;*/
  }

.translation {
    font-family: "Source Serif Pro";
    margin-top: -.9em;
    margin-bottom: 1.2em;
    padding-left: 15px;
    font-size: 1em;
    color: #777777;
}

.has-validation-warning {
    background-color: #ff00002c;
}

.focused-element-warning {
    background-color: #ff00002c;
    padding: 0.5em;
}

.filter-column {
    vertical-align:top;
    display: inline-block;
    margin-right: 1.5em;
    /*width: 25%*/
}

/* TODO underline gets cutoff for "group" label */
.filter-column label {
    margin: .3em;
    padding: .3em;
    display:block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

