
.wg-sheet {
    height:37em;
  }

.wg-sheet-right-sidepanel {
    border: 1px solid gray;
    border-left: 1px solid transparent;
    overflow-y: auto;
    padding: 1em;
    width:30em;
    height: 100%;
    float:right;
}

.wg-sheet-left-sidepanel {
    width:calc(100% - 30em);
    height: 100%;
    outline: none;
    border: 1px solid gray;
}

.wg-filter-column {
    vertical-align:top;
    display: inline-block;
    margin-right: 1.5em;
    /*width: 25%*/
}

.wg-sheet-left-bottom{
    /* height:calc(100% - 7em); */
    height:100%;
}

.wg-sheet-word-groups {
    height:100%;
    /*height:calc(100% - 5em);*/
}

.GridScrollWrapper {
    position: relative;
    transition: height 0.3s;
    border-bottom: 1px solid #e9e9e9;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
}

.GridContainer {
    padding-left: 3px;
    position: relative;
    height: 100%;
}

.GridPlaceholder {
    text-align: center;
    height: 53px;
    background: #fff;
    padding: 15px;
}

.GridScrollDummy {
    position: relative;
    min-width: 100%;
}

.VirtualizedRows {
    width: 100%;
    /*font-family: "Roboto";*/
    font-family: "Nunito";
    /*font-family: "Roboto Slab";*/
    /*font-weight: 200;*/
    user-select: none;
    border-collapse: collapse;
    position: absolute;
    top: 0;
    box-sizing: border-box;
}

.GridRow {
    padding-left: 5px;
    border-left: 7px solid transparent;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    /*border-left: 7px solid #33b5e5;*/
    /*border: 2px solid transparent;*/
}

.GridRowActive {
    border-left: 7px solid #33b5e5;
    /*border: 2px solid #33b5e5;*/
}
